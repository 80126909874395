@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "animate.css/animate.css";

label{
  @apply select-none;
}

.form-label{
  @apply  block
          text-base
          font-medium
          pl-1
          text-gray-700;
}

.form-input{
  @apply  mt-1
          focus:ring-ab-green-500
          focus:border-ab-green-500
          block
          w-full
          shadow-sm
          sm:text-base
          border-gray-300
          rounded-md;
}

.form-simple-button{
  @apply  select-none
          bg-ab-green-200
          text-ab-green-1000
          py-2
          px-4
          border
          border-ab-green-300
          rounded-md shadow-sm
          font-medium
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-gray-500
          disabled:opacity-60
          disabled:bg-white
          hover:bg-ab-green-100;
}
.form-primary-button{
  @apply  inline-flex
          justify-center
          py-2
          px-4
          border
          border-transparent
          shadow-sm
          font-medium
          rounded-md
          text-white
          bg-ab-green-1000
          hover:bg-ab-green-900
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          disabled:opacity-60
          disabled:bg-gray-500
          focus:ring-ab-green-500;
}
.form-danger-button{
  @apply  inline-flex
          justify-center
          py-2
          px-4
          border
          border-transparent
          shadow-sm
          font-medium
          rounded-md
          text-white
          bg-ab-coral-1000
          hover:bg-ab-coral-800
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          disabled:opacity-60
          disabled:bg-gray-500
          focus:ring-red-500;
}
.form-simple-danger-button{
  @apply  select-none
          bg-white
          py-2
          px-4
          border
          border-ab-coral-500
          rounded-md shadow-sm
          font-medium
          text-ab-coral-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-ab-coral-500
          disabled:opacity-60
          disabled:bg-white
          hover:bg-ab-coral-100;
}
.page-header{
  @apply  text-xl 
          sm:text-2xl 
          font-bold 
          leading-tight 
          text-ab-green-1100 
          sm:ml-6 
          text-center 
          sm:text-left;
}